import { Paragraph, pictograms } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import Box from "../Box";
import InlineCode from "../InlineCode";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { type PictogramsBlock as PictogramsBlockType } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function PictogramsBlock(props: PictogramsBlockType) {
  return (
    <Box noPadding noShadow transparent {...bem("")}>
      {props.pictograms?.map((pictogram) => {
        if (!pictogram.name) return null;

        const pictogramName = pictogram.name.trim() as keyof typeof pictograms;
        const { cleaned } = vercelStegaSplit(pictogramName || "");
        const Pictogram = { ...pictograms }[cleaned];

        if (!Pictogram) return null;

        return (
          <div key={`${props.pictograms?.length}_${pictogramName}`} {...bem("row")}>
            <Pictogram {...bem("pictogram")} />
            <span {...bem("code")}>
              <InlineCode copy={`<${pictogramName} />`}>{pictogramName}</InlineCode>
            </span>
            <Paragraph size="sm" {...bem("description")}>
              {pictogram.description || null}
            </Paragraph>
          </div>
        );
      })}
    </Box>
  );
}
