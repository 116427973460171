import { FC } from "react";
import { Flex, Paragraph } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import Box from "../Box";
import InlineCode from "../InlineCode";

import { PreviewProps } from "./components/types";
import { ColorPreview, ElevationPreview, RadiusPreview, SpacingPreview } from "./components";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import type { DesignTokens } from "@/types/sanity";

const bem = BEMHelper(styles);

const getPreview = (category: string): FC<PreviewProps> | null => {
  if (category === "color") return ColorPreview;
  if (category === "spacing") return SpacingPreview;
  if (category === "elevation") return ElevationPreview;
  if (category === "radius") return RadiusPreview;
  if (category === "gradient") return null;
  return null;
};

const formatToken = (category: string, token: string): string => {
  if (category === "spacing") return `spacing-${token}`;
  if (category === "elevation") return `elevation-${token}`;
  if (category === "radius") return `radius-${token}`;
  return token;
};

export default function DesignTokensComponent({ selection }: DesignTokens) {
  if (!selection || !selection.category || !selection.tokens) return <></>;
  const { category: rawCategory, tokens } = selection;
  const { cleaned: category } = vercelStegaSplit(rawCategory);
  const Preview = getPreview(category);

  const type =
    category === "color" &&
    tokens[0] &&
    tokens.every((token) => token.name?.startsWith(tokens[0].name?.split("-")[0] || ""))
      ? tokens[0].name?.split("-")[0]
      : undefined;

  interface FilteredTokens {
    name: string;
    description?: string;
  }

  const filteredTokens = tokens.filter((token) => token.name) as FilteredTokens[];

  if (!filteredTokens.length) return <></>;

  return (
    <Box noPadding noShadow transparent {...bem("")}>
      {filteredTokens.map((token) => {
        const tokenName = token.name.trim();
        return (
          <Flex
            key={`${category}-${token.name}`}
            align="center"
            gap="spacing-100"
            horizontal
            justify="space-between"
            {...bem("row")}
          >
            <>
              {Preview && token.name && <Preview token={token.name} type={type} />}
              <InlineCode copy={`var(--${tokenName})`}>
                {formatToken(category, tokenName)}
              </InlineCode>
              <Paragraph className={styles.token_description} size="sm">
                {token.description}
              </Paragraph>
            </>
          </Flex>
        );
      })}
    </Box>
  );
}
