"use client";

import { ReactNode } from "react";
import { Button, CopyIcon, Flex } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";

const bem = BEMHelper(styles);

type Props = { children?: ReactNode; copy?: boolean | string; className?: string };

export default function InlineCode({ children, className, copy = false }: Props) {
  const { cleaned, encoded } = vercelStegaSplit(`${children}`);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(typeof copy === "string" ? copy : cleaned);
    } catch (e: any) {
      console.warn("Clipboard failed. Most likely blocked by browser:", e.message);
    }
  };

  return (
    <Flex align="center" gap="spacing-100" horizontal {...bem("", undefined, className)}>
      {copy && (
        <Button
          aria-label="Copy to clipboard"
          density="compact"
          iconStart={<CopyIcon />}
          onClick={handleCopy}
          variant="ghost"
        />
      )}

      <code>{children}</code>
      <span style={{ display: "none" }}>{encoded}</span>
    </Flex>
  );
}
