import { Paragraph, systemIcons } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import Box from "../Box";
import InlineCode from "../InlineCode";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { type IconsBlock as IconsBlockType } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function IconsBlock(props: IconsBlockType) {
  return (
    <Box noPadding noShadow transparent {...bem("")}>
      {props.icons?.map((icon) => {
        if (!icon.name) return null;

        const iconName = icon.name.trim() as keyof typeof systemIcons;
        const { cleaned } = vercelStegaSplit(iconName || "");
        const Icon = { ...systemIcons }[cleaned];

        if (!Icon) return null;

        return (
          <div key={`${props.icons?.length}_${iconName}`} {...bem("row")}>
            <Icon size={24} {...bem("icon")} />
            <span {...bem("code")}>
              <InlineCode copy={`<${iconName} />`}>{iconName}</InlineCode>
            </span>
            <Paragraph size="sm" {...bem("description")}>
              {icon.description || null}
            </Paragraph>
          </div>
        );
      })}
    </Box>
  );
}
